.contact-background{
  height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    z-index: 100;
    font-size: 1rem;
}


.footerLinkImg{
    width: 50px;
    height: 50px; 
    padding: 12px;
  }
  
.contact-container {
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
  }