.navbar-background{
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 60%;
    background-color: rgba(240, 241, 217, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between links */
    padding: 1px;
    z-index: 100;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    font-size: 30px;
    margin-top: 25px;
    color:#D2B48C ;
    height: 80px;
    z-index: 999; /* Sits above other elements */
    box-sizing: border-box;
    position: fixed;
}

.navbar-background ul {
  display: flex; /* Makes the links in one row */
  justify-content: center; /* Centers the links inside the navbar */
  align-items: center; /* Aligns them vertically */
  margin: 0; /* Resets default margins */
  padding: 0; /* Resets default padding */
}


.nav-links {
    list-style: none;
    display: flex;
    gap: 16px;
    margin: 5px;
    padding: 0 50px 0 0;
    justify-content: center;
    align-items: center;
}

.navbar-background li {
  list-style: none; /* Removes bullets from list items */
  margin: 0 15px; /* Adds spacing between links */
}

.navbar-background a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits color from parent */
}

@media (max-width: 900px) {
  .navbar-background {
    max-width: 60%; /* Increases max width to fill more of the screen */
    font-size: 25px; /* Reduces the font size */
    padding: 5px; /* Adds more padding for smaller screens */
    gap: 10px; /* Reduces the space between links */
    height: 60px; /* Reduces the height */
    margin-top: 10px; /* Adjusts the top margin for a better layout */
  }

  .nav-links {
    gap: 10px; /* Reduces gap between links */
    padding: 0 20px; /* Adjusts padding */
  }

  .navbar-background li {
    margin: 0 10px; /* Reduces spacing between links */
  }
}

@media (max-width: 700px) {
  .navbar-background {
    max-width: 100%; /* Increases max width to fill more of the screen */
    font-size: 25px; /* Reduces the font size */
    padding: 5px; /* Adds more padding for smaller screens */
    gap: 10px; /* Reduces the space between links */
    height: 60px; /* Reduces the height */
    margin-top: 10px; /* Adjusts the top margin for a better layout */
  }

  .nav-links {
    gap: 10px; /* Reduces gap between links */
    padding: 0 20px; /* Adjusts padding */
  }

  .navbar-background li {
    margin: 0 10px; /* Reduces spacing between links */
  }
}
