.about-container{
    font-size: 1.2rem;
    line-height: 1.6;
    padding: 1.5rem;
    color: #5C4033;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    max-width: 900px;
    text-align: center;
}

   .about-content {
    position: relative;
    z-index: 2;
    max-width: 70%;
    font-size: 18px;
    padding: 20px;
  }
  
  /* Medium devices (tablets, 700px to 950px) */
  @media (max-width: 950px) {
    .about-background {
      height: 90vh;
    }
  
    .about-background .title {
      font-size: 32px;
    }
  
    .about-background .about-content {
      max-width: 80%;
      font-size: 16px;
    }
  }
  
  /* Small devices (tablets and phones, 600px to 700px) */
  @media (max-width: 700px) {
    .about-background {
      height: 100vh;
    }
  
    .about-background .title {
      font-size: 28px;
    }
  
    .about-background .about-content {
      max-width: 85%;
      font-size: 15px;
    }
  }
  
  /* Extra small devices (phones, 375px to 600px) */
  @media (max-width: 600px) {
    .about-background {
      height: 90vh;
    }
  
    .about-background .title {
      font-size: 24px;
    }
  
    .about-background .about-content {
      max-width: 90%;
      font-size: 14px;
    }
  }
  
  /* Ultra small devices (small phones, < 375px) */
  @media (max-width: 375px) {
    .about-background {
      height: 80vh;
    }
  
    .about-background .title {
      font-size: 20px;
    }
  
    .about-background .about-content {
      max-width: 95%;
      font-size: 13px;
    }
  }
  