.home-background {
    background-position: center;
    background-size: cover;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    padding: 2rem;
    font-size: 1rem;
    position: relative;
    z-index: 100;
    color: #5C4033;
  }
  
  .home-container {
    font-size: 1.2rem;
    line-height: 1.6;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally inside container */
    text-align: center;  /* Center text inside container */
    border-radius: 12px;
    max-width: 90%;
    transition: transform 0.3s ease;
  }
  